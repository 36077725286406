import React, { FunctionComponent, ReactNode, Ref, useEffect, useRef } from 'react'

export type inViewOnChangeCallback = (inView: boolean) => void
export type inViewProps = {
  onChange: inViewOnChangeCallback
  root?: HTMLElement
  rootMargin?: string
  threshold?: number
  children?: ReactNode | ((ref: Ref<HTMLDivElement>) => ReactNode)
}

export const InView: FunctionComponent<inViewProps> = ({ children, onChange, root, rootMargin, threshold }) => {
  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const tg = entries.at(0)
        if (!tg) return
        onChange(tg.isIntersecting)
      },
      {
        root,
        rootMargin,
        threshold,
      }
    )

    observer.observe(rootRef.current!)

    return () => {
      observer.disconnect()
    }
  }, [onChange, root, rootMargin, threshold])

  if (typeof children === 'function') {
    return children(rootRef)
  }

  return <div ref={rootRef}>{children}</div>
}
